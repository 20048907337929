<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Associate User To Company
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels multiple v-model="panels">
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
            >
              <v-expansion-panel-header>
               <h3>Associate User To Company: {{ companyData.name }}</h3> 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>
                  Please click on a user to get a confirmation prompt then it
                  will associate them with the company.
                </p>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  @click:row="handleClick"
                  :headers="headers"
                  :items="userList"
                  :search="search"
                  :items-per-page="-1"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
            >
              <v-expansion-panel-header>
                <h3>Remove User From Company: {{ companyData.name }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>
                  Please click on a user to get a confirmation prompt then it
                  will remove them from the company.
                </p>
                <v-text-field
                  v-model="search_remove"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  @click:row="handleClickRemove"
                  :headers="headers"
                  :items="companyData.users"
                  :search="search_remove"
                  :items-per-page="-1"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>


    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

export default {
  data() {
    return {
        panels: [0, 1],
      search: "",
      search_remove: "",

      headers: [
        {
          text: "First Name",
          align: "start",
          filterable: true,
          value: "first_name",
        },
        {
          text: "Last Name",
          align: "start",
          filterable: true,
          value: "last_name",
        },
        {
          text: "Email",
          align: "start",
          filterable: true,
          value: "email",
        },
        {
          text: "Phone",
          align: "start",
          filterable: true,
          value: "phone",
        },

        {
          text: "Created",
          value: "createdAt",
        },
      ],
      componentLoading: true,
      error: "",
      userList: [],
      companyData: {},
    };
  },
  methods: {
    async handleClick(value) {
      //   this.$router.push(`/dashboard/admin/user/read/${value.id}`);

      let confirm_user_associate = window.confirm(
        `Are you sure you want to associate ${value.first_name} ${value.last_name} with ${this.companyData.name}?`
      );

      if (confirm_user_associate) {
        try {
          await API().post(
            `/api/internal-admin/associate_user_to_company/?company_id=${this.companyData.id}&user_id=${value.id}`
          );

          console.log("user confirmed");
          this.componentLoading = true;
          this.loadPageData();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async handleClickRemove(value) {
      //   this.$router.push(`/dashboard/admin/user/read/${value.id}`);

      let confirm_user_associate_remove = window.confirm(
        `Are you sure you want to remove ${value.first_name} ${value.last_name} from ${this.companyData.name}?`
      );

      if (confirm_user_associate_remove) {
        try {
          await API().post(
            `/api/internal-admin/remove_user_from_company/?company_id=${this.companyData.id}&user_id=${value.id}`
          );

          console.log("user confirmed");
          this.componentLoading = true;
          this.loadPageData();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async loadPageData() {
      if (this.$route.query.company_id) {
        try {
          let user_list = await API().get(
            `/api/internal-admin/admin_get_user_list`
          );
          this.userList = user_list.data;

          let company = await API().get(
            `/api/internal-admin/company/${this.$route.query.company_id}`
          );
          if (company.data) {
            this.companyData = company.data;
          }

          this.userList = this.userList.filter((el) => {
            if (!this.companyData.users.find((u) => u.id == el.id)) {
              return el.id;
            }
          });

          this.componentLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  async mounted() {
    this.loadPageData();
  },
  filters: {
    ...filters,
  },
};
</script>

<style lane="scss" scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>